.wrapper {
  @apply flex items-center justify-between space-x-4;

  .noLabel & {
    @apply col-span-2;
  }
}

.label {
  @apply callout mb-0 whitespace-nowrap md:h3;
}

.cinemas {
  @apply md:max-w-[250px];
}
