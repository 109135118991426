.control {
  @apply form-select w-full rounded border-line bg-main stroke-white pb-[calc(0.5rem_-_1px)] text-body
  shadow transition-all duration-300
  placeholder:text-neutral-300 focus:border-neutral-600 focus:shadow-neutral-600 focus:ring-neutral-600
  dark:bg-neutral-800 dark:focus:border-neutral-500;

  & option {
    @apply bg-main py-3 text-body hover:bg-neutral-500;
  }
}

:global(.field-error) .control {
  @apply border-red-600 focus:border-red-600 focus:shadow-red-600
  focus:ring-red-600;
}

.primary {
  @apply border-primary bg-primary stroke-current text-white placeholder:text-white focus:border-primary focus:ring-primary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}

.default {
}
