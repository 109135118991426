.root {
  @apply relative;
}

.scroll {
  @apply overflow-x-auto px-6 scrollbar-hide;
}

.dates {
  @apply flex w-full min-w-max items-center justify-center space-x-4;
}

.date {
  @apply inline-block whitespace-nowrap px-2 text-center md:px-4;
}

.dateSelected {
  @apply border-b-2 border-b-primary;
}

.month {
  @apply block whitespace-nowrap text-xs;
}

.day {
  @apply block first-letter:uppercase;
}

.button {
  @apply pointer-events-none absolute top-0 flex aspect-square h-full items-center text-2xl text-primary/20 transition-colors;
}

.leftButton {
  @apply left-0 justify-start bg-gradient-to-r from-white to-white/0 dark:from-neutral-900 dark:to-neutral-900/0;
}

.rightButton {
  @apply right-0 justify-end bg-gradient-to-l from-white to-white/0 dark:from-neutral-900 dark:to-neutral-900/0;
}

.activeButton {
  @apply pointer-events-auto text-primary;
}
